<template>
  <draggable v-model="products" handle=".item-product-handle">
    <b-row
      v-for="(product, i) in products"
      :key="i"
      align-v="center"
      class="mt-4"
    >
      <b-col cols="9">
        <b-media no-body class="align-items-center">
          <ProductThumbnail :product="product" />
          <b-media-body>
            <p v-if="product.vigieId" class="h6 text-muted mb-0">
              Code produit: {{ product.vigieId }}
            </p>
            <p
              v-if="product.isbn13 || product.isbn10"
              class="h6 text-muted mb-0"
            >
              ISBN: {{ product.isbn13 || product.isbn10 }}
            </p>
            <p v-if="product.name" class="h5 mb-0">
              {{ product.name }}
            </p>
          </b-media-body>
        </b-media>
      </b-col>

      <b-col v-if="!readonly" align="end"> </b-col>
    </b-row>
  </draggable>
</template>
<script>
import draggable from 'vuedraggable'
import ProductThumbnail from '@/components/Product/ProductThumbnail'

export default {
  components: {
    ProductThumbnail,
    draggable,
  },
  props: {
    category: {
      type: Object,
      required: true,
    },
    itemId: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    item() {
      const index = this.category.ListCategoryItems.findIndex(
        (item) => item.id === this.itemId
      )
      return this.category.ListCategoryItems[index]
    },
    products: {
      get() {
        return this.item.products
      },
      set(value) {
        this.$store.dispatch(
          'schoolLists/categories/updateCategoryItemProducts',
          {
            category: this.category,
            item: this.item,
            products: value,
          }
        )
      },
    },
  },
}
</script>
